import {
  addDays,
  addHours,
  addMonths,
  endOfDay,
  endOfMonth,
  endOfWeek,
  endOfYear,
  startOfMonth,
  startOfWeek,
  startOfYear,
} from "date-fns";

import AWS from 'aws-sdk';

import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
dayjs.extend(utc);
dayjs.extend(timezone);

export const predefinedRanges = [
  {
    label: "Hoy",
    value: [
      dayjs.tz(new Date(), "America/El_Salvador"),
      dayjs.tz(new Date(), "America/El_Salvador"),
    ],
  },
  {
    label: "Ayer",
    value: [
      addDays(addHours(new Date(), -6), -1),
      addDays(addHours(new Date(), -6), -1),
    ],
  },
  {
    label: "Esta semana",
    value: [
      startOfWeek(new Date(), { weekStartsOn: 1 }),
      endOfWeek(new Date(), { weekStartsOn: 1 }),
    ],
  },
  {
    label: "Semana pasada",
    value: [
      startOfWeek(addDays(new Date(), -7), { weekStartsOn: 1 }),
      endOfWeek(addDays(new Date(), -7), { weekStartsOn: 1 }),
    ],
  },
  {
    label: "Este mes",
    value: [startOfMonth(new Date()), endOfMonth(new Date())],
  },
  {
    label: "Mes pasado",
    value: [
      startOfMonth(addMonths(new Date(), -1)),
      endOfMonth(addMonths(new Date(), -1)),
    ],
  },
  {
    label: "Este año",
    value: [startOfYear(new Date()), endOfYear(new Date())],
  },
];

export const uploadFileToS3 = async (file, finishCallBack) => {
  const S3_BUCKET = process.env.NEXT_PUBLIC_VOUCHERS_S3_BUCKET;
  const REGION = process.env.NEXT_PUBLIC_VOUCHERS_S3_REGION;

  AWS.config.update({
    accessKeyId: process.env.NEXT_PUBLIC_VOUCHERS_S3_ACCESS_KEY_ID,
    secretAccessKey: process.env.NEXT_PUBLIC_VOUCHERS_S3_ACCESS_KEY,
  });
  const s3 = new AWS.S3({
    params: { Bucket: S3_BUCKET },
    region: REGION,
  });
  console.log('file => 1317 => ', file);
  const params = {
    Bucket: S3_BUCKET,
    Key: file.name,
    Body: file,
    ACL: 'public-read',
  };

  const upload = s3
    .putObject(params)
    .on('httpUploadProgress', (evt) => {
      console.log(`Uploading ${parseInt((evt.loaded * 100) / evt.total)}%`);
      // setImageUploadingProgress(`Uploading ${parseInt((evt.loaded * 100) / evt.total)}%`);
    })
    .promise();

  await upload.then((status) => {
    finishCallBack(status);
    // alert("File uploaded successfully.");
  });
};

export const subtractDays = (date, days) => {
  const newDate = new Date(date);
  newDate.setDate(newDate.getDate() - days);
  return (
    newDate.getFullYear() +
    "-" +
    (newDate.getMonth() + 1) +
    "-" +
    newDate.getDate()
  ).toLocaleString();
};


export const validateIdsForVoucherModuleProd = (id) => {
  return [10017,20076,30152,10016,30391,30429,30447,20067,10016].includes(id);
}