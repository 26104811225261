import * as React from "react";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import Paper from "@mui/material/Paper";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";

import {
  useMediaQuery,
  useTheme,
  Drawer,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import Link from "next/link";
import menuItems from "./items";
import useAgentStore from "src/hooks/UseStore";
import { AuthContext } from "src/contexts/FirebaseAuthContext";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CloseIcon from "@mui/icons-material/Close";
import { validateIdsForVoucherModuleProd } from "src/utils/core";

export default function FixedBottomNavigation({ hasPendingVoucher }) {
  const [value, setValue] = React.useState(0);
  const [openDrawer, setOpenDrawer] = React.useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const agentType = useAgentStore((state) => state.agentType);
  const { user } = React.useContext(AuthContext);
  let id = user?.id_agente;
  let items = menuItems(agentType, id)[0].items;

  let id_agente;
  if (agentType === "Concesionario") {
    id_agente = user?.id_concesionario;
  } else if (agentType === "SubConcesionario") {
    id_agente = user?.id_subconcesionario;
  } else if (agentType === "Agente") {
    id_agente = user?.id_agente;
  }

  if (validateIdsForVoucherModuleProd(id_agente)) {
    items[3]['items']?.push({
        "name": "Comprobantes bancarios",
        "link": "/vouchers",
        "icon": {
            "type": {},
            "compare": null
        }
    });

    items = [
      ...items,
      {
        name: "Deposito",
        link: "/management/voucher",
        icon: AccountBalanceWalletIcon,
      }
    ];
  }

  const canViewActas = user?.id_concesionario || user?.id_subconcesionario;
  const flattenedItems = items.filter((item) => !item.items);

  if (!isMobile) {
    return null;
  }

  const handleReportesClick = () => {
    setOpenDrawer(true);
  };

  const handleDrawerClose = () => {
    setOpenDrawer(false);
  };

  return (
    <Paper
      sx={{ position: "fixed", bottom: 0, left: 0, right: 0 }}
      elevation={3}
    >
      <BottomNavigation
        value={value}
        onChange={(event, newValue) => {
          setValue(newValue);
        }}
      >
        {flattenedItems.map((item, index) => {
          return (
            <BottomNavigationAction
              disabled={item.name == 'Deposito' && hasPendingVoucher ? true : false}
              key={index}
              label={item.name}
              icon={<item.icon />}
              component={Link}
              href={item.link}
              sx={{ minWidth: 10, minHeight: 10 }}
            />
          );
        })}
        <BottomNavigationAction
          label="Actas"
          icon={<ExpandMoreIcon />}
          onClick={handleReportesClick}
        />
      </BottomNavigation>

      <Drawer
        anchor="bottom"
        open={openDrawer}
        onClose={handleDrawerClose}
        PaperProps={{ sx: { maxHeight: "50%" } }}
      >
        <List>
          <ListItem button onClick={handleDrawerClose}>
            <CloseIcon />
            <ListItemText primary="Cerrar" />
          </ListItem>
          {(canViewActas &&
            items
              .find((item) => item.name === "Actas")
              ?.items?.map((subItem, index) => (
                <ListItem
                  key={index}
                  component={Link}
                  href={subItem.link}
                  button
                >
                  <ListItemText primary={subItem.name} />
                </ListItem>
              ))) || (
            <ListItem>
              <ListItemText primary="No hay actas disponibles" />
            </ListItem>
          )}
        </List>
      </Drawer>
    </Paper>
  );
}
