import { BarChartTwoTone } from "@mui/icons-material";
import StoreIcon from "@mui/icons-material/Store";
import HomeIcon from "@mui/icons-material/Home";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import PointOfSaleIcon from "@mui/icons-material/PointOfSale";
import ReceiptLongOutlinedIcon from "@mui/icons-material/ReceiptLongOutlined";
import SavingsOutlinedIcon from "@mui/icons-material/SavingsOutlined";
import SportsSoccerIcon from "@mui/icons-material/SportsSoccer";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import PeopleIcon from "@mui/icons-material/People";
import SummarizeIcon from '@mui/icons-material/Summarize';

const menuItems = (agentType, id) => [
  {
    items: [
      {
        name: "Inicio",
        link: "/",
        icon: HomeIcon,
      },

      // ...(agentType == 'Agente' && (id === 30385 || id === 303001) ? [
      ...(agentType == "Agente"
        ? [
            {
              name: "Apuestas",
              link: "/sports",
              icon: SportsSoccerIcon,
            },
          ]
        : []),

      ...(agentType == "Agente"
        ? [
            {
              name: "Caja",
              link: "/management/recargas",
              icon: PointOfSaleIcon,
            },
            {
              name: "Actas",
              items: [
                {
                  name: "Recargas",
                  link: "/management/withdrawals",
                  icon: ReceiptLongOutlinedIcon,
                },
                {
                  name: "Premios",
                  link: "/management/prizes",
                  icon: EmojiEventsIcon,
                },
                {
                  name: "Comisiones",
                  link: "/management/comissions",
                  icon: SavingsOutlinedIcon,
                },
                {
                  name: "Afiliados",
                  link: "/management/affiliates",
                  icon: PeopleIcon,
                }
              ],
            },
            {
              name: "Comisiones",
              link: "/management/comissions",
              icon: SavingsOutlinedIcon,
            },
          ]
        : []),

      ...(agentType !== "Agente"
        ? [
            {
              name: "Gestion de Agentes",
              icon: StoreIcon,
              link: "/management/agents/tree",
            },
            {
              name: "Actas",
              link: "/management/withdrawals",
              icon: ReceiptLongOutlinedIcon,
            },
            {
              name: "Comisiones",
              link: "/management/comissions",
              icon: SavingsOutlinedIcon,
            }
          ]
        : []),
    ],
  },
];

export default menuItems;
