import {BarChartTwoTone} from "@mui/icons-material";
import StoreIcon from '@mui/icons-material/Store';
import HomeIcon from '@mui/icons-material/Home';
import PointOfSaleIcon from '@mui/icons-material/PointOfSale';
import SportsSoccerIcon from '@mui/icons-material/SportsSoccer';


const menuItems = (agentType, id) => [
  // Itmes left side
  {
    heading: "Administracion",
    items: [
      {
        name: "Inicio",
        link: "/",
        icon: HomeIcon,
      },
      ...(agentType == 'Agente' ? [
      {
        name: "Caja",
        link: "/management/recargas",
        icon: PointOfSaleIcon,
      },
      {
        name: "Reportes",
        link: "/dashboard/reports",
        icon: BarChartTwoTone,
        items: [
          {
            name: "Comisiones",
            link: "/management/comissions",
          },
          {
            name: "Recargas",
            link: "/management/withdrawals",
          },
          {
            name: "Premios",
            link: "/management/prizes",
          },
          {
            name: "Afiliados",
            link: "/management/affiliates",
          }
        ],
      },] : []),

      // ...(agentType == 'Agente' && (id === 303001 || id === 30385 || id === 30382 || id === 313131 || id === 30439) ? [
      ...(agentType == 'Agente' ? [
        {
        name: "Apuestas",
        link: "/",
        icon: SportsSoccerIcon,
        items: [
          {
            name: "SportsBook",
            link: "/sports",
          },
          {
            name: "Historial de Apuestas",
            link: "/sports/history",
          }
        ],
      },
        ] : []),

      ...(agentType !== 'Agente' ? [{
        name: "Gestion de Agentes",
        icon: StoreIcon,
        items: [
          {
            name: "Arbol de Agentes",
            link: "/management/agents/tree",
          }
        ],
      },      {
        name: "Reportes",
        link: "/dashboard/reports",
        icon: BarChartTwoTone,
        items: [
          {
            name: "Comisiones",
            link: "/management/comissions",
          },
          {
            name: "Transacciones",
            link: "/management/withdrawals",
          }
        ],
      },
    ] : [])
    ],
  }
];

export default menuItems;