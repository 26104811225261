import { useRef, useState } from "react";
import { useAuth } from "src/hooks/useAuth";
import { useRouter } from "next/router";
import { useContext } from "react";
import { AuthContext } from "src/contexts/FirebaseAuthContext";
import { Avatar, Box, Button, Divider, IconButton, Popover, Typography, styled, useTheme } from "@mui/material";
import LockOpenTwoToneIcon from "@mui/icons-material/LockOpenTwoTone";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";

const UserBoxButton = styled(IconButton)(
  ({ theme }) => `
  width: ${theme.spacing(4)};
  padding: 0;
  height: ${theme.spacing(4)};
  margin-left: ${theme.spacing(1)};
  border-radius: ${theme.general.borderRadiusLg};
  
  &:hover {
    background: ${theme.colors.primary.main};
  }
`
);

const UserAvatar = styled(Avatar)(
  ({ theme }) => `
        height: 90%;
        width: 90%;
        border-radius: ${theme.general.borderRadiusLg};
`
);

function HeaderUserbox() {
  const theme = useTheme();
  const router = useRouter();
  const { logout } = useAuth();
  const { user } = useContext(AuthContext);
  const userName = user?.nombres || "";
  const userAvatar = user?.avatar || "";
  const ref = useRef(null);
  const [isOpen, setOpen] = useState(false);

  const handleToggle = () => {
    setOpen(prev => !prev);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleLogout = async () => {
    handleClose();
    await logout();
    router.push("/auth/login/basic");
  };

  const handleProfile = () => {
    try {
      router.push("/management/profile");
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <>
      <UserBoxButton color="primary" ref={ref} onClick={handleToggle}>
        <UserAvatar alt={userName} src={userAvatar} />
      </UserBoxButton>
      <Popover
        disableScrollLock
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Box m={1} mt={8}>
          <Button fullWidth color="warning" onClick={handleProfile}>
            <AccountCircleIcon fontSize="medium" sx={{ mr: 2 }} />
            Perfil
          </Button>
        </Box>
        <Divider />
        <Box m={1}>
          <Button color="primary" fullWidth onClick={handleLogout}>
            <LockOpenTwoToneIcon
              sx={{
                mr: 1,
              }}
            />
            Cerrar sesion
          </Button>
        </Box>
      </Popover>
    </>
  );
}

export default HeaderUserbox;
