import { useQuery } from '@tanstack/react-query';

const fetchBalance = async ({ queryKey }) => {
  const [_, { id, agentType }] = queryKey;
  const response = await fetch(`/api/getUserBalance?id=${id}&type=${agentType}`);
  if (!response.ok) {
    throw new Error('Error al obtener el saldo');
  }
  const { saldo, configurations } = await response.json();
  return { saldo, configurations };
};

export const useBalance = (id, agentType) => {
  return useQuery({
    queryKey: ['saldo', { id, agentType }],
    queryFn: fetchBalance,
    enabled: !!id && !!agentType,
    refetchInterval: 20000, 
    staleTime: 5000,
    cacheTime: 1000 * 60 * 5,
  });
};