import { create } from "zustand";
import { persist, createJSONStorage } from "zustand/middleware";

const useConfigurationsStore = create(
  persist(
    (set) => ({
      configurations: {
        award: {
          status: false,
          description: "",
        },
        topup: {
          status: false,
          description: "",
        },
        access: {
          status: false,
          description: "",
        },
        tickets: {
          status: false,
          description: "",
        },
      },
      setConfigurations: (configurations) => {
        set({ configurations: configurations });
      },
    }),
    {
      name: "Configurations",
      storage: createJSONStorage(() => {
        return localStorage;
      }),
    }
  )
);

export default useConfigurationsStore;
