import { Box, styled, Divider, useTheme, useMediaQuery } from "@mui/material";
import SidebarTopSection from "./SidebarTopSection";
import SidebarMenu from "./SidebarMenu";
import { useContext } from "react";
import { SidebarContext } from "src/contexts/SidebarContext";
import Drawer from '@mui/material/Drawer';
import Scrollbar from "src/components/Scrollbar";

const SidebarWrapper = styled(Box)(
  ({ theme, isMobile }) => `
    width: ${isMobile ? '200px' : theme.sidebar.width};
    color: ${theme.colors.alpha.trueWhite[70]};
    background: linear-gradient(60deg, #29323c 0%, #485563 100%);
    z-index: 0;
    height: 100%;
    margin-top: ${theme.header.height};
    padding-bottom: 61px;
    padding-top: 60px;
`
);

function Sidebar() {
  const { sidebarToggle, toggleSidebar } = useContext(SidebarContext);
  const closeSidebar = () => toggleSidebar();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Drawer
      open={sidebarToggle}
      onClose={closeSidebar}
      transitionDuration={200}
    >
      <SidebarWrapper isMobile={isMobile}>
        <Scrollbar>
          <SidebarTopSection />
          <Divider
            sx={{
              my: theme.spacing(5),
              mx: theme.spacing(3),
              background: theme.colors.alpha.trueWhite[10],
            }}
          />
          <SidebarMenu />
        </Scrollbar>
      </SidebarWrapper>
    </Drawer>
  );
}

export default Sidebar;
